import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { RadioGroup } from '@headlessui/react'
import { WorkspaceMember, WorkspaceMemberRole } from '@/api/core'
import { useMemberOwnedContactsCount, useMembers } from '@/api/member'
import { WorkspaceMemberRoleLabels } from '@/api/text/workspace'
import { useWorkspaceMemberDelete, useWorkspaceMemberReminder, useWorkspaceMemberUpdate } from '@/api/workspace'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { useUserContext } from '@/providers/User/context'
import { Button, Dialog, Select, Text } from '@/ui'

type MemberCardProps = {
  member: WorkspaceMember
  canEdit: boolean
}

export function MemberCard({ member, canEdit }: MemberCardProps) {
  const toast = useToast()
  const memberUpdate = useWorkspaceMemberUpdate()
  const memberRemind = useWorkspaceMemberReminder()
  const { user: self } = useUserContext()
  const [memberToRemove, setMemberToRemove] = useState<WorkspaceMember | null>(null)

  const onRoleChange = (memberId: string, newRole: WorkspaceMemberRole) => {
    memberUpdate.mutate(
      {
        memberId: memberId,
        role: newRole,
      },
      {
        onError: (err: unknown) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to change user role', error: true })
        },
        onSuccess: () => {
          toast.createToast({ message: 'User role changed' })
        },
      },
    )
  }
  const onReminder = (memberId: string) => {
    memberRemind.mutate(memberId, {
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to send reminder', error: true })
      },
      onSuccess: () => {
        toast.createToast({ message: 'Reminder sent' })
      },
    })
  }

  return (
    <>
      {memberToRemove && <RemoveDialog member={memberToRemove} onFinish={() => setMemberToRemove(null)} />}
      <div className={clsx('flex items-center py-[10px]', canEdit && 'group')}>
        <div className="item-center mr-4 grid h-9 w-9 content-center rounded-full bg-gray-300 text-center">
          <div className="text-md font-medium  text-white">
            {member.email.toUpperCase().charAt(0) || member.first_name.toUpperCase().charAt(0) || 'X'}
          </div>
        </div>

        {member.pending ? (
          <div>
            <Text variant="text" className="pr-4 font-medium text-medium">
              Invited
            </Text>
            <Text variant="text" className="w-32 truncate text-ellipsis text-medium">
              {member.email}
            </Text>
          </div>
        ) : (
          <div>
            <div className="flex flex-row">
              <Text variant="text" className="pr-4 font-medium text-dark">
                {member.first_name} {member.last_name}
              </Text>
              {member.owner && (
                <Text variant="text" className="text-medium">
                  Owner
                </Text>
              )}
            </div>
            <Text variant="text" className="w-64 truncate text-ellipsis">
              {member.email}
            </Text>
          </div>
        )}

        <div className="ml-auto flex items-center space-x-2">
          {member.pending && (
            <Button
              variant="basic"
              className="invisible p-4.5 group-hover:visible"
              onClick={() => onReminder(member.member_id)}
              disabled={memberRemind.isPending}
            >
              Reminder
            </Button>
          )}
          {self.value?.traits.email !== member.email && (
            <Button className="invisible p-4.5 group-hover:visible" onClick={() => setMemberToRemove(member)}>
              Remove
            </Button>
          )}

          {canEdit ? (
            <Select
              options={WorkspaceMemberRoleLabels}
              defaultValue={
                WorkspaceMemberRoleLabels.find((v) => v.value === member.role) || WorkspaceMemberRoleLabels[0]
              }
              value={WorkspaceMemberRoleLabels.find((v) => v.value === member.role) || WorkspaceMemberRoleLabels[0]}
              onChange={(v) => onRoleChange(member.member_id, v.value)}
            />
          ) : (
            <Text variant="text">
              {(WorkspaceMemberRoleLabels.find((v) => v.value === member.role) || WorkspaceMemberRoleLabels[0]).label}
            </Text>
          )}
        </div>
      </div>
    </>
  )
}

type RemoveDialogProps = {
  member: WorkspaceMember
  onFinish: () => void
}

function RemoveDialog(props: RemoveDialogProps) {
  const { member, onFinish } = props
  const { data: contactsCount, status } = useMemberOwnedContactsCount(member.member_id)
  const toast = useToast()
  const memberDelete = useWorkspaceMemberDelete()
  const [open, setOpen] = useState(true)
  const [openAssignDialog, setOpenAssignDialog] = useState(false)

  if (status === 'pending') {
    return null
  }
  if (status === 'error') {
    toast.createToast({ message: 'Failed to fetch member details', error: true })
    return null
  }

  const onCancel = () => {
    onFinish()
    setOpen(false)
  }

  const onDelete = (contactNewOwnerId?: string) => {
    memberDelete.mutate(
      { memberId: member.member_id, contactNewOwnerId: contactNewOwnerId || '' },
      {
        onError: (err: unknown) => {
          const message = (err as any)?.body?.message || 'Failed to remove member'
          toast.createToast({ message, error: true })
        },
        onSuccess: () => toast.createToast({ message: 'Member removed' }),
        onSettled: () => {
          onFinish()
          setOpen(false)
        },
      },
    )
  }

  const onRemove = () => {
    if (contactsCount?.data.count > 0) {
      setOpen(false)
      setOpenAssignDialog(true)
    } else {
      onDelete()
    }
  }

  const onAssign = (newOwnerId: string) => {
    onDelete(newOwnerId)
  }

  return (
    <>
      {openAssignDialog && (
        <AssignOwnerDialog
          memberToDelete={member}
          onAssign={onAssign}
          onCancel={onCancel}
          contactsCount={contactsCount.data.count}
        />
      )}
      <Dialog open={open} onClose={onCancel}>
        <Dialog.Panel className="max-w-80 space-y-4">
          <Text variant="subtitle">Remove {member.first_name + ' ' + member.last_name} from this organization?</Text>
          <div className="flex justify-between gap-2">
            <Button className="w-full" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="accent" className="w-full" onClick={onRemove}>
              Remove
            </Button>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  )
}

type AssignOwnerDialogProps = {
  memberToDelete: WorkspaceMember
  onCancel: () => void
  onAssign: (memberId: string) => void
  contactsCount: number
}

function AssignOwnerDialog(props: AssignOwnerDialogProps) {
  const { memberToDelete, onAssign, onCancel, contactsCount } = props
  const toast = useToast()
  const { data, status } = useMembers()
  const [newOwnerId, setNewOwnerId] = useState('')
  const [open, setOpen] = useState(true)
  const onClose = () => {
    onCancel()
    setOpen(false)
  }

  const options = useMemo(() => data?.data.filter((member) => member.id !== memberToDelete.member_id) ?? [], [data])
  useEffect(() => {
    if (status === 'success') {
      setNewOwnerId(options[0].id)
    }
  }, [options, status])

  if (status === 'pending') {
    return null
  }
  if (status === 'error') {
    toast.createToast({ message: 'Failed to fetch member details', error: true })
    return null
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Panel className="max-w-80">
        <Text variant="subtitle">Assign contacts</Text>
        <Text className="mb-2 text-dusk">
          {contactsCount} contacts are owned by {memberToDelete.first_name + ' ' + memberToDelete.last_name} and must be
          reassigned before removal
        </Text>

        <RadioGroup value={newOwnerId} onChange={setNewOwnerId} className="flex flex-col justify-center space-y-2">
          {options.map((member) => (
            <RadioGroup.Option key={member.id} value={member.id}>
              {({ checked }) => (
                <RadioGroup.Label className="flex items-center">
                  {checked ? (
                    <div className="mr-4 size-4.5 rounded-full border-[5px] border-accent" />
                  ) : (
                    <div className="mr-4 size-4.5 rounded-full border border-[#DFE0E2]" />
                  )}
                  <Text>{member.name}</Text>
                </RadioGroup.Label>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>

        <div className="mt-4 flex justify-between gap-2">
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="accent" onClick={() => onAssign(newOwnerId)}>
            Assign
          </Button>
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}
